import React from "react"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import Heading from "../components/heading/heading"
import Layout from "../components/layout"
import "../styles/news.scss";

const BlogPostTemplate = ({ data: { prismicBlogPost } }) => {

  const { data } = prismicBlogPost
  return (
    <div className="blog-post">
      <Layout>
        <SEO title={data.title.text} socialImage={data.social_image.url} />
        {/* <PageHeader title={data.title.text} date={data.publish_date} /> */}
        <div key={data.uid} className="mt-4">
          <Heading
            title={data.title.text}
            copy={data.publish_date}
            styleKey="secondary"
            marginBottom={25}
          />
          <img src={data.featured_image.url} alt="blog post" className="w-100" />
        </div>
        <div className="entry-content">
          <div dangerouslySetInnerHTML={{ __html: data.post_copy.html }}></div>
        </div>
      </Layout>
    </div>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($uid: String!) {
    prismicBlogPost(uid: { eq: $uid }) {
      uid
      data {
        title {
          text
        }
        publish_date(formatString: "MMMM DD, YYYY")
        post_copy {
          html
        }
        social_image {
          url
        }
        featured_image {
          url
        }
      }
    }
  }
`